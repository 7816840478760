import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';

import { useDeleteCommunityMutation, usePinCommunityMutation } from '@store/endpoints/community';
import { updateActiveChatInfo } from '@store/reducers/chats';
import { toggleSigninModal } from '@store/reducers/modal';
import { RootState } from '@store/store';

import ClickAway from '@components/partials/clickaway';
import Image from '@components/partials/Avatar';

import { dropdownType } from '@data/dropdown';

import styles from './headers.module.scss';

const CommunityHeader = () => {
	const user = useSelector((state: RootState) => state.user.user);
	const chat = useSelector((state: any) => state.chats);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showDropdown, setShowDropdown] = useState(false);

	const [deleteCommunity] = useDeleteCommunityMutation();
	const [pin] = usePinCommunityMutation();

	const handleNavigate = () => {
		navigate(`/community/about?cid=${chat.activeChatId}`);
	};

	const shouldShow = (option: string) => {
		if (option === 'Info') return true;
		else {
			if (chat.activeChatInfo?.isMember) {
				if (chat.activeChatInfo?.isPinned && option === 'UnPin') return true;
				if (!chat.activeChatInfo?.isPinned && option === 'Pin') return true;
			} else return false;
		}
		return false;
	};

	const handlePin = () => {
		if (!user) {
			dispatch(toggleSigninModal());
			toast.error('Please login to pin community');
			return;
		}
		pin({ communityId: chat.activeChatId })
			.unwrap()
			.then(data => {
				toast.success(`Community ${chat.activeChatInfo?.isPinned ? 'Unpinned' : 'Pinned'}`);
				dispatch(updateActiveChatInfo({ isPinned: !chat.activeChatInfo?.isPinned }));
			})
			.catch(error => toast.error('Something went wrong'));
	};

	const handleDelete = () => {
		if (!user) {
			dispatch(toggleSigninModal());
			toast.error('Please login to delete community');
			return;
		}

		deleteCommunity({ communityId: chat.activeChatId })
			.unwrap()
			.then(data => {
				toast.success(`Community deleted successfully`);
				navigate('/');
			})
			.catch(error => toast.error('Something went wrong'));
	};

	const dropDownItemTriggers = (key: string) => {
		switch (key) {
			case 'Kick':
				toast.info('Feature launching soon!');
				break;
			case 'Report':
				toast.info('Feature launching soon!');
				break;
			case 'Archive':
				toast.info('Feature launching soon!');
				break;
			case 'Delete':
				handleDelete();
				break;
			case 'Info':
				handleNavigate();
				break;
			case 'Pin':
			case 'UnPin':
				handlePin();
				break;
		}
	};

	return (
		<header className={styles.header}>
			<div className={styles.main}>
				<Image
					loader='md2'
					errStyle={{ fontSize: '3rem' }}
					alt={chat.activeChatInfo?.name}
					src={chat.activeChatInfo?.profile}
				/>
				{chat.activeChatInfo?.community_type === 'private' ? (
					<Icon icon='material-symbols:lock' className={styles.lock} />
				) : null}
				<div className={styles.info} onClick={handleNavigate}>
					<h2 className={styles.title}>{chat.activeChatInfo?.name}</h2>
					<h3 className={styles.subtitle}>{chat.activeChatInfo?.memberCount} Members</h3>
				</div>
			</div>
			<div className={styles.chatHome}>
				<ClickAway onClickAway={() => setShowDropdown(false)}>
					<button className={styles.dots} onClick={() => setShowDropdown(prev => !prev)}>
						<Icon icon={'mdi:dots-vertical'} color='black' width={24} height={24} />
						<ul className={`${styles.list} ${showDropdown ? styles.showdots : ''} ${styles.options__container}`}>
							{dropdownType('COMMUNITY_DEFAULT').map((item: any, i) => (
								<React.Fragment key={i}>
									{shouldShow(item.title) ? (
										<li key={i} onClick={() => dropDownItemTriggers(item.title)} className={styles.item__options}>
											<Icon icon={item.icon} color='black' width={20} height={20} />
											{item.title}
										</li>
									) : null}
								</React.Fragment>
							))}
						</ul>
					</button>
				</ClickAway>
			</div>
		</header>
	);
};

export default CommunityHeader;
