import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Route, Routes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';

import { UserResponse, useGetLoggedInUserQuery } from '@store/endpoints/user';
import { useGetUsersReactionQuery } from '@store/endpoints/post-likes';
import { setReactions } from '@store/reducers/reactions';
import { setUser } from '@store/reducers/user';

import { onMessageListener, requestPermission } from '@utils/firebaseInit';
import { getCookies } from '@utils/cookie';
import Loader from '@components/loaders';
import Pages from '@pages';

import CommunityLayout from '@layouts/community';
import ProfileLayout from '@layouts/profile';
import HomeLayout from '@layouts/home';
import Layout from '@layouts/index';

export default function App() {
	let data: UserResponse | undefined = undefined,
		isLoading = null;
	({ data, isLoading } = useGetLoggedInUserQuery(null));
	const user = useSelector((state: any) => state.user.user);
	const { data: reactions, isLoading: reactionLoading, refetch } = useGetUsersReactionQuery(null, { skip: !user?._id });
	const dispatch = useDispatch();

	onMessageListener()
		.then((payload: any) => {
			const { title, body } = payload.notification;
			toast.info(`${title}`);
		})
		.catch(err => {
			toast.error(JSON.stringify(err));
		});

	//firebase token setup

	useEffect(() => {
		const firebaseToken = getCookies('firebase-token');
		if (data?.result[0].email && !firebaseToken)
			requestPermission({ userId: data.result[0]._id, email: data.result[0].email, isIos: false });
		// return {};
	}, [data]);

	//Fetching User Data

	useEffect(() => {
		if (data?.result) {
			dispatch(setUser(data.result[0]));
		}
	}, [data?.success]);

	//Fetching Reactions User data

	useEffect(() => {
		if (user?._id) {
			refetch();
		}
	}, [user]);

	useEffect(() => {
		if (reactions) {
			dispatch(setReactions(reactions));
		}
	}, [reactions, reactionLoading]);

	//JSX

	return (
		<>
			{isLoading ? (
				<Loader.PageLoader />
			) : (
				<>
					<ToastContainer
						position='top-right'
						autoClose={1000}
						hideProgressBar={false}
						newestOnTop={true}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable
						pauseOnHover
						style={{ zIndex: 99999 }}
					/>
					<Suspense fallback={<Loader.PageLoader />}>
						<Routes>
							<Route element={<Layout />}>
								<Route path='/' element={<HomeLayout />}>
									<Route index element={<Pages.PostFeed />} />
									<Route path='post' element={<Pages.Post />} />
									<Route path='search' element={<Pages.Search />} />
									<Route path='notification' element={<Pages.Notification />} />
									<Route path='communities' element={<Pages.CommunityList />} />
								</Route>
								<Route path='/community' element={<CommunityLayout />}>
									<Route path='about' element={<Pages.CommunityInfo />} />
									<Route path='chats' element={<Pages.CommunityChats />} />
									<Route path='announcements' element={<Pages.CommunityAnnouncements />} />
									<Route path='messageinfo' element={<Pages.MessageInfo />} />
								</Route>
								<Route path='/profile/:username' element={<ProfileLayout />}>
									<Route index element={<Pages.ProfileAbout />} />
									<Route path='edit' element={<Pages.ProfileUpdate />} />
									<Route path='activity/:type' element={<Pages.ProfileActivity />} />
									<Route path='network/:type' element={<Pages.ProfileNetwork />} />
								</Route>
								<Route path='*' element={<Pages.Page404 />} />
							</Route>
						</Routes>
					</Suspense>
				</>
			)}
		</>
	);
}
